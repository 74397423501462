import * as React from 'react'

import { ProductBlockProps } from '@thg-commerce/enterprise-components'
import {
  HorizontalAlignment,
  i18n,
  useSiteConfig,
  useTheme,
} from '@thg-commerce/enterprise-core'
import { ProductBlockPropsWithBeacons } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Widget/FlagshipWidget'
import { Beacon } from '@thg-commerce/enterprise-network/src/transformers/sponsoredAds/products'
import { Image } from '@thg-commerce/gravity-elements'
import { Directions } from '@thg-commerce/gravity-elements/Product/types'
import {
  Carousel,
  CarouselButtonPlacement,
} from '@thg-commerce/gravity-patterns'

import {
  FirstSideGridItem,
  LeftSideTitle,
  ProductBlockContainer,
  StyledButton,
  StyledGrid,
  StyledGridItem,
  StyledProductBlock,
  Subtitle,
  Title,
} from './styles'

export enum Orientation {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

type ImageStyle = 'cover' | 'contain'

export interface ProductsShowcaseProps {
  title?: {
    text: string
  } | null
  subtitle?: {
    text: string
    margin?: string
    subtitleTextAlign?: HorizontalAlignment
  } | null
  leftSideContent?: {
    title: { text: string; useH1?: string }
    subtitle: { text: string }
    ctaButton?: { text: string; link: string; ariaLabel?: string }
  }
  image?: {
    source: string
    alt?: string
    imageUrl?: string
    style?: {
      imageStyle?: ImageStyle
      width?: string
      height?: string
    }
  }
  mobileImage?: {
    source: string
    alt?: string
    imageUrl?: string
  }
  productList: (ProductBlockProps & {
    inStock?: boolean
  })[]
  productCount?: number
  className?: string
  orientation?: Orientation
  alignText?: HorizontalAlignment
  carouselView: boolean
  contentAlignments: {
    gridColumns: number
    gridItemColumns?: {
      firstItem: {
        colStart: number | number[]
        colSpan: number | number[]
        rowStart: number | number[]
      }
      secondItem: {
        colStart: number | number[]
        colSpan: number | number[]
        rowStart: number | number[]
      }
    }
    productContent: {
      titleAlignment?: HorizontalAlignment
      directions: Directions
      display?: string
      style?: {
        width?: string
        sidePadding?: number
      }
    }
  }
  showPdpLinkWhenOutOfStock?: boolean
  hideProductShowCaseMarketedSpecialOfferBadge?: boolean
  carouselControlsPosition?: CarouselButtonPlacement
  isSponsoredAdsBanner?: boolean
  carouselLoopSlides?: boolean
  forwardedRef?: React.RefObject<HTMLDivElement>
  inViewport?: boolean
  enterCount?: number
  sponsoredAdsBeacons?: {
    onClickBeacon?: Beacon
    onLoadBeacon?: Beacon
    onViewBeacon?: Beacon
  }
  displayProductButton?: boolean
  removeATags?: boolean
}

export const ProductsShowcase = (props: ProductsShowcaseProps) => {
  const i18nText = {
    carouselText: {
      leftScrollLabel: i18n('carousel.controls.left.arialabel'),
      rightScrollLabel: i18n('carousel.controls.right.arialabel'),
    },
    reviewsStarsLabel: i18n('reviews.stars.label'),
    reviewsLabel: i18n('reviews.reviews.label'),
    buyAriaLabel: i18n('general.productbuynow.label'),
    buyText: i18n('general.productbuynow.text'),
    quickBuyText: i18n('general.product.quickbuy.text'),
    soldOutText: i18n('general.productsoldout.text'),
  }

  const { enableClickAndCollect, enableSponsoredAds } = useSiteConfig()
  const theme = useTheme()

  const shouldFireBeacons =
    enableSponsoredAds &&
    props.inViewport &&
    props.enterCount &&
    props.enterCount < 2

  if (shouldFireBeacons && props.sponsoredAdsBeacons?.onViewBeacon) {
    navigator.sendBeacon(props.sponsoredAdsBeacons.onViewBeacon.url)
  }

  React.useEffect(() => {
    if (props.sponsoredAdsBeacons?.onLoadBeacon) {
      navigator.sendBeacon(props.sponsoredAdsBeacons.onLoadBeacon.url)
    }

    props.productList?.map((sponsoredAd: ProductBlockPropsWithBeacons) => {
      if (sponsoredAd?.sponsoredAdsBeacons?.onLoadBeacon) {
        navigator.sendBeacon(sponsoredAd?.sponsoredAdsBeacons.onLoadBeacon.url)
      }
    })
  }, [])

  const styledImage = (
    <Image
      src={[
        {
          url: props.mobileImage?.source || props.image?.source || '',
          breakpoint: 'sm',
        },
        {
          url: props.image?.source || props.mobileImage?.source || '',
          breakpoint: 'lg',
        },
      ]}
      alt={props.image?.alt || 'styled-image'}
      width={props.image?.style?.width || '100%'}
      height={props.image?.style?.height || '100%'}
      imageStyle={props.image?.style?.imageStyle}
      isAmp={false}
      onClick={() => {
        if (
          enableSponsoredAds &&
          props.isSponsoredAdsBanner &&
          props.sponsoredAdsBeacons?.onClickBeacon
        ) {
          navigator.sendBeacon(props.sponsoredAdsBeacons.onClickBeacon.url)
        }
      }}
    />
  )

  const productsShowcaseImage = props.image?.imageUrl ? (
    <a href={props.image.imageUrl} data-testid="imageUrl">
      {styledImage}
    </a>
  ) : (
    styledImage
  )

  const layoutProperties =
    props.orientation === Orientation.VERTICAL
      ? {
          imageColSpan: 12,
          carouselColStart: 1,
          carouselRowStart: 2,
          carouselColSpan: 12,
          itemsPerSlide: [1, 2, 4, 4],
        }
      : {
          imageColSpan: theme.widget.productsShowcase.imageColSpan,
          carouselColStart: theme.widget.productsShowcase.carouselColStart,
          carouselRowStart: [2, 1],
          carouselColSpan: [12, 7, 7, 7],
          itemsPerSlide: theme.widget.productsShowcase.itemsPerSlide,
        }

  const screenReaderReviewText = ({ score, total }) =>
    `${score} ${i18nText.reviewsStarsLabel} ${total} ${i18nText.reviewsLabel}`
  const productBlocks = props.productList.map((product, index) => {
    const buttonData = product.inStock
      ? {
          ariaLabel: i18nText.buyAriaLabel,
          title: i18nText.buyText,
          quickBuyTitle: i18nText.quickBuyText,
          productInStock: product.inStock,
        }
      : {
          title: i18nText.soldOutText,
          quickBuyTitle: '',
          disabled: !props.showPdpLinkWhenOutOfStock,
        }

    return (
      <ProductBlockContainer
        orientation={props.orientation}
        width={props.contentAlignments.productContent.style?.width}
        padding={props.contentAlignments.productContent.style?.sidePadding}
      >
        <StyledProductBlock
          key={index}
          hideGap={props.isSponsoredAdsBanner}
          {...product}
          removeATags={props.removeATags}
          review={
            product.review
              ? {
                  ...product.review,
                  screenReaderOnlyText: screenReaderReviewText({
                    score: product.review.starRating,
                    total: product.review.numberOfReviews,
                  }),
                }
              : undefined
          }
          attributes={{
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: theme.widget.productsShowcase.itemMaxWidth,
          }}
          titleAlignment={props.contentAlignments.productContent.titleAlignment}
          marketedSpecialOffer={
            props.hideProductShowCaseMarketedSpecialOfferBadge
              ? null
              : product.marketedSpecialOffer
          }
          button={
            !props.isSponsoredAdsBanner && props.displayProductButton
              ? buttonData
              : undefined
          }
          orientation={props.orientation}
          directions={props.contentAlignments.productContent.directions}
          swatch={product.swatch || undefined}
          externalIdentifier={product.externalIdentifier || undefined}
          fulfilmentMethodIconsProps={
            enableClickAndCollect
              ? {
                  enableClickAndCollect,
                  isCheckStock: product.fulfilmentMethodIconsProps
                    ?.isCheckStock!,
                  isOrderInStore: product.fulfilmentMethodIconsProps
                    ?.isOrderInStore!,
                  inStock: product.fulfilmentMethodIconsProps?.inStock!,
                  inStockLocations:
                    product.fulfilmentMethodIconsProps?.inStockLocations,
                  weightGroups:
                    product.fulfilmentMethodIconsProps?.weightGroups,
                  leadTime: product.fulfilmentMethodIconsProps?.leadTime,
                  isBookable: product.fulfilmentMethodIconsProps?.isBookable,
                  iconsAvailability: product.fulfilmentMethodIconsProps
                    ?.iconsAvailability!,
                  i18nText: product.fulfilmentMethodIconsProps?.i18nText!,
                }
              : undefined
          }
          content={product.content}
          brand={{
            name: product.brand?.name || '',
            imageUrl: product.brand?.imageUrl,
          }}
        />
      </ProductBlockContainer>
    )
  })

  return (
    <div
      ref={props.forwardedRef}
      className={props.className}
      data-testid="products-showcase-container"
    >
      {props.title && (
        <Title alignment={props.alignText || 'center'}>
          {props.title.text}
        </Title>
      )}
      {props.subtitle && !props.isSponsoredAdsBanner && (
        <Subtitle alignment={props.alignText || 'center'}>
          {props.subtitle.text}
        </Subtitle>
      )}
      <StyledGrid
        sideMargin={!props.carouselView}
        hideBottomMargin={props.isSponsoredAdsBanner}
        columns={props.contentAlignments.gridColumns}
        rows={1}
        isSponsoredAdsBanner={props.isSponsoredAdsBanner}
      >
        <FirstSideGridItem
          colStart={
            props.contentAlignments.gridItemColumns?.firstItem.colStart || 1
          }
          rowStart={
            props.contentAlignments.gridItemColumns?.firstItem.rowStart || 1
          }
          colSpan={
            props.contentAlignments.gridItemColumns?.firstItem.colSpan ||
            layoutProperties.imageColSpan
          }
          rowSpan={1}
          orientation={props.orientation}
          isSponsoredBanner={props.isSponsoredAdsBanner}
          data-testid="first-side-grid-item"
        >
          {props.leftSideContent ? (
            <React.Fragment>
              {props.leftSideContent.title && (
                <LeftSideTitle
                  as={
                    props.leftSideContent.title.useH1 === 'true' ? 'h1' : 'h2'
                  }
                  alignment={props.alignText || 'center'}
                >
                  {props.leftSideContent.title.text}
                </LeftSideTitle>
              )}
              {props.leftSideContent.subtitle && (
                <Subtitle alignment={props.alignText || 'center'}>
                  {props.leftSideContent.subtitle.text}
                </Subtitle>
              )}
              {props.leftSideContent.ctaButton && (
                <StyledButton
                  emphasis="high"
                  renderedAs="a"
                  href={props.leftSideContent.ctaButton.link}
                  aria-label={props.leftSideContent.ctaButton.ariaLabel || ''}
                >
                  {props.leftSideContent.ctaButton.text}
                </StyledButton>
              )}
            </React.Fragment>
          ) : (
            productsShowcaseImage
          )}
        </FirstSideGridItem>
        <StyledGridItem
          colStart={
            props.contentAlignments.gridItemColumns?.secondItem.colStart ||
            layoutProperties.carouselColStart
          }
          rowStart={
            props.contentAlignments.gridItemColumns?.secondItem.rowStart ||
            layoutProperties.carouselRowStart
          }
          colSpan={
            props.contentAlignments.gridItemColumns?.secondItem.colSpan ||
            layoutProperties.carouselColSpan
          }
          rowSpan={1}
          orientation={props.orientation}
          contentDisplay={props.contentAlignments.productContent.display}
        >
          {props.subtitle && props.isSponsoredAdsBanner && (
            <Subtitle
              isSponsoredBanner={props.isSponsoredAdsBanner}
              alignment={props.alignText || 'center'}
              subtitleTextAlign={props.subtitle.subtitleTextAlign || 'center'}
              margin={props.subtitle.margin}
            >
              {props.subtitle.text}
            </Subtitle>
          )}
          {props.carouselView ? (
            <Carousel
              items={productBlocks}
              i18n={i18nText.carouselText}
              itemsPerSlide={layoutProperties.itemsPerSlide}
              isAmp={false}
              buttonPlacement={
                props.carouselControlsPosition || CarouselButtonPlacement.Split
              }
              hideControlsOnSingleSlide={true}
              inactiveSlideFullOpacity={true}
              indicatorStyle="letterbox"
              data-testid="carousel"
              loopSlides={props.carouselLoopSlides}
            />
          ) : (
            productBlocks
          )}
        </StyledGridItem>
      </StyledGrid>
    </div>
  )
}
